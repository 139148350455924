<template>
  <div class="wrapper">
    <div class="page">
      <div id="loader"></div>
      <div class="page-inner" id="main_page">
        <!-- end test -->
        <div class="va-card">
          <div class="row">
            <div class="col-md-12">
              <div class="dash_card_body">
                <div class="header_section">
                  <h4 class="menu_header">Offers</h4>
                  <div class="">
                    <div class="btn-toolbar" v-if="adminRoles.includes(user.role.id)">
  
                      <!-- modal for confirmation -->
                      
                      <!-- end -->
                      <router-link :to="{ name: 'new_offer'}">
                        <button class="btn btn-default custom_btn custon_orange">
                          <i class="fa fa-plus"></i> New Offers
                        </button>
                      </router-link>
                    </div>
                  </div>
                </div>
                <div class="search_section">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group has-search mt-1">
                        <span class="fa fa-search form-control-feedback"></span>
                        <input type="text" v-model="search" @keyup="searchOffers()" aria-required="false" autocomplete="on" inputmode="text" name="search_input" placeholder="Search..." class="form-control">
                      </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group has-search">
                        <button class="btn btn-orange search mt-1">Search</button>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-2">
                      <select class="form-control" v-model="status" @change="getOffers">
                        <option value="">--Select status --</option>
                        <option value="1">Active</option>
                        <option value="2">Stopped</option>
                      </select>
                    </div>
                    
                   
                      
                  </div>
                </div>
              </div>
              <hr>
            </div>
              
            <div class="col-lg-12">
              <div class="dash_card_body">
                <div class="table_section">
                  <table class="table  table-hover">
                    <thead>
                      <tr>
                        <th scope="col" width="40%">Offer</th>
                        <th scope="col">Status</th>
                        <th scope="col">Start Date</th>
                        <th scope="col">End Date</th>
                        <th scope="col"> Action </th>
                      </tr>
                    </thead>
                    <tbody v-if="!loading">
                      <tr v-for="offer in offers" :key="offer.id"  draggable="true" @drop="onDrop($event,offer)" @dragenter.prevent @dragover.prevent @dragstart="startDrag($event,offer)">
                        <td>
                          <router-link :to="{ name: 'updateoffer', params: { offerId:offer.id }}">
                            <span><strong>{{offer.name}}</strong></span> <br>
                            <p class="small_desc_text" v-html="offer.description" ></p>
                          </router-link>
                        </td>
                        <td>
                          <span :class="{'active': offer.status,'disabled' : !offer.status}">
                            {{offer.status ? 'Active' : 'Stopped'}}
                          </span>
                        </td>
                        <td>
                          <span>{{formatDate(offer.start_date,1)}}</span>
                        </td>
                        <td>
                          <span>{{formatDate(offer.end_date,1)}}</span>
                        </td>
                        <td>
                          <div class="btn-group statusgroup">
                            <button type='button' class="btn btn-sm custom_btn btn-default">
                              <span v-if="offer.status == 1">
                                <i class='fa fa-check' style="color:green;"></i>
                              </span>
                              <span v-else-if="offer.status = 2">
                                <i class="fa fa-close" style="color:red;" aria-hidden="true"></i>
                              </span>
                            </button>
                            <button v-if="adminRoles.includes(user.role.id)" type="button" class="btn btn-sm custom_btn btn-default dropdown-toggle" data-toggle="dropdown" :id="'dropdownMenuButton'+offer.id">
                              <span class="caret"></span>
                              <span class="sr-only">Toggle Dropdown</span>
                            </button>

                            <div class="dropdown-menu" :aria-labelledby="'dropdownMenuButton'+offer.id">
                              <a class="dropdown-item" @click="editOffer(offer.id, 'start')" href="#">Start</a>
                              <router-link :to="{ name: 'updateoffer', params: { offerId:offer.id }}">
                                <span class="dropdown-item" >Edit</span>
                              </router-link>
                              <a class="dropdown-item" @click="editOffer(offer.id,'stop')" href="#">Stop</a>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot v-if="!loading">
                      <tr>
                        <td colspan="8">
                          <div id="pagination" >
                            <Pagination :total-pages="totalPages" :per-page="recordsPerPage" :current-page="page" @pagechanged="onPageChange" />
                            <ul class="showItems">
                              <li>Show Items:
                                <select @change="onChangeRecordsPerPage" v-model="recordsPerPage">
                                  <option :value="10">10</option>
                                  <option :value="20">20</option>
                                  <option :value="30">30</option>
                                  <option :value="40">40</option>
                                  <option :value="50">50</option>
                                </select>
                              </li>
  
                            </ul>
                          </div>
  
                        </td>
                      </tr>
                    </tfoot>
                  </table>
  
                </div>
              </div>
              <div v-if="loading" class="loading-cover">
                <div class="loader"> </div>
              </div>
  
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import ReusableFunctionMixins from '@/mixins/ReusableFunctionMixins';
  import ReusableDataMixins from '@/mixins/ReusableDataMixins';
  import Pagination from '@/pages/pagination/pagination.vue';
  export default {
      components: {
          Pagination,
      },
      data() {
          return {
              offers: [],
              loading: false,
              page: 1,
              totalPages: 0,
              recordsPerPage:this.$store.state.offersRecordPerPage,
              search: '',
              status: ''
          }
      },
      mounted: async function () {
          this.getOffers();
          // disable input if use is not admin or superadmin
  
      },
      methods: {
        startDrag(event, offer) {     
          event.dataTransfer.dropEffect = 'move'      
          event.dataTransfer.effectAllowed = 'move' 
          event.dataTransfer.setData('offer_id',offer.id);
          // evt.dataTransfer.setData('itemID', item.id)    
        },
        async onDrop(event,offer){
          const offerid = event.dataTransfer.getData('offer_id')
          try{
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json','Content-Type': 'application/json' }
            };
            const url = `${this.baseUrl}/offers/position`;
            const payload = {
              'merchant_id':parseInt(this.merchantID),
              'operation_type': 0,
              'update':{
                'id':offerid,
                'position': offer.position
              }
            }
            const response = await this.axios.patch(url,payload, config);
            if(response.status ==200){
              this.getOffers();
            }

          }catch(error){
            console.log('error')
          }      
        },
          searchOffers() {
              clearTimeout(this.debounce);
              this.debounce = setTimeout(() => {
                  this.getOffers();
              }, 1500)
          },
          async editOffer(id,type) {
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            }; 
            const url = `${this.baseUrl}/offers/status`;
            const payload = {
              'merchant_id': parseInt(this.merchantID),
              'ids': [id],
              'status': type == 'start' ? 1:2
            }
            const response = await this.axios.patch(url,payload,config)
            if(response.status === 200){
              this.getOffers()
            }
          },
          async getOffers(){
            this.loading = true;
            const config = {
                headers: { 'Authorization': `Bearer ${this.token}`, 'Accept': 'application/json' }
            };
            let url = `${this.baseUrl}/offers`;
            url = new URL(url);
            url.searchParams.set('merchant_id', this.merchantID);
            url.searchParams.set('page', this.page);
            url.searchParams.set('size', this.recordsPerPage);
            if(this.search){
                url.searchParams.set('q', this.search)
            }
            if(this.status){
                url.searchParams.set('status',this.status)
            }

            const response = await this.axios.get(url,config)
            if(response.status === 200){
                this.loading = false;
                this.offers = response.data.data;
                this.totalPages = Math.ceil(response.data.total / this.recordsPerPage)
                this.totalRecords = response.data.total
            }
            
          },
          onPageChange(page) {
              this.page = page
              this.getOffers();
          },
          onChangeRecordsPerPage() {
            const payload = {
              "type":"offers",
              "size":this.recordsPerPage
            }
            this.$store.commit("setPageLimit",payload)
            // setPageLimit
              this.getOffers();
          }
      },
      mixins: [ReusableFunctionMixins, ReusableDataMixins]
  }
  </script>
  